import { Card, Col, Layout, Row, Statistic } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getMovimentoEmAberto, getSaldoReceitaDespesa, getSintetico } from "../../shared/services/api";
import ChartBar from "./chartBar";
import ChartLineNegative from "./chartLineNegative";
import ChartLinePositive from "./chartLinePositive";
import ChartPie from "./chartPie";
import TablePie from "./tablePie";

const { Content } = Layout;

const Saldo = (props: {contaCorrenteId: number}) => {  
  const [balance, setBalance] = useState([]);
  const [summary, setSumary] = useState([]);
  const [creditCardBalance, setCreditCardBalance] = useState<number>(0);
  const [paymentBalance, setPaymentBalance] = useState<number>(0);
  const [dueDateBalance, setdueDateBalance] = useState<number>(0);
  const [openBalance, setOpenBalance] = useState<number>(0);
  const [openRevenueBalance, setOpenRevenueBalance] = useState<number>(0);
  const [openExpenseBalance, setOpenExpenseBalance] = useState<number>(0);
  const [competencia] = useState(dayjs(new Date()).startOf('month'))

  useEffect(() => {
    fetchBalanceRevenues(props.contaCorrenteId);
  }, [props.contaCorrenteId]);

  const fetchBalanceRevenues = async (contaCorrenteId: number) => {
    const response = await getSaldoReceitaDespesa(contaCorrenteId, competencia.year());
    
    setBalance(response.data.data);
  }

  useEffect(() => {
    fetchBalanceSummary(props.contaCorrenteId)
  }, [props.contaCorrenteId]);

  const fetchBalanceSummary = async (contaCorrenteId: number) => {
    const response = await getSintetico(contaCorrenteId, competencia.toDate());
    
    setSumary(response.data.data);
  }

  useEffect(() => {
    fetchOpenBalance(props.contaCorrenteId)
  }, [props.contaCorrenteId]);
  
  const fetchOpenBalance = async (contaCorrenteId: number) => {
    const response = await getMovimentoEmAberto(contaCorrenteId);
    
    const { totalBalance, revenueBalance, expenseBalance } = response.data.data.reduce(
      (acc: { totalBalance: number, revenueBalance: number, expenseBalance: number }, 
       item: { valor: number }) => {
        const valor = item.valor;
        return {
          totalBalance: acc.totalBalance + valor,
          revenueBalance: valor > 0 ? acc.revenueBalance + valor : acc.revenueBalance,
          expenseBalance: valor < 0 ? acc.expenseBalance + valor : acc.expenseBalance
        };
      },
      { totalBalance: 0, revenueBalance: 0, expenseBalance: 0 }
    );
  
    setOpenBalance(totalBalance);
    setOpenRevenueBalance(revenueBalance);
    setOpenExpenseBalance(expenseBalance);
  };
  
  return (
    <Layout>
      <Content style={{ overflow: 'auto', height: 'calc(80vh - 80px)', padding: '5px' }}>
        <Row gutter={[10, 24]}>
          <Col xs={12} sm={8} md={4} lg={4}>
            <Card hoverable>
              <Statistic title="Receitas em Aberto" valueStyle={{ color: '#236ecf' }} value={openRevenueBalance} precision={2} />      
            </Card>
          </Col>
          <Col xs={12} sm={8} md={4} lg={4}>
            <Card hoverable>
              <Statistic title="Despesas em Aberto" valueStyle={{ color: '#ff6347' }} value={openExpenseBalance} precision={2} />      
            </Card>
          </Col>          
          <Col xs={12} sm={8} md={4} lg={4}>
            <Card hoverable>
              <Statistic title="Saldo em Aberto" value={openBalance} precision={2} />      
            </Card>
          </Col>
          {/* <Col xs={12} sm={8} md={4} lg={4}>
            <Card hoverable>
              <Statistic title="Total Cartão de Credito (MM/YYYY)" valueStyle={{ color: '#ff6347' }} value={creditCardBalance} precision={2} />      
            </Card>
          </Col>
          <Col xs={12} sm={8} md={4} lg={4}>
            <Card hoverable>
              <Statistic title="Total Pago (MM/YYYY)" valueStyle={{ color: '#ff6347' }} value={paymentBalance} precision={2} />      
            </Card>
          </Col>
          <Col xs={12} sm={8} md={4} lg={4}>
            <Card hoverable>
              <Statistic title="Vencimentos Hoje" valueStyle={{ color: '#ff6347' }} value={dueDateBalance} precision={2} />      
            </Card>
          </Col> */}
        </Row>
        <Row gutter={[10, 10]}style={{ marginTop: '10px' }}>
          <Col xs={24} sm={12} md={12} lg={6}>
            <Card hoverable title="Receitas e despesas no ano corrente" size="small" style={{ height: '100%' }}>
              <ChartBar balance={balance}/>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6}>
            <Card hoverable title="Receitas e despesas por mês" size="small" style={{ height: '100%' }}>
              <ChartLinePositive balance={balance}/>
              <ChartLineNegative balance={balance}/>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6}>
            <Card hoverable title="Receitas e despesas sintéticas mês corrente" size="small" style={{ height: '100%' }}>
              <ChartPie balance={summary}/> 
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6}>
            <Card hoverable title="Receitas e despesas sintéticas mês corrente" size="small" style={{ height: '100%' }}>
              <TablePie balance={summary}/>
            </Card>
          </Col>              
        </Row>
      </Content>
    </Layout>
  )
}

export default Saldo;
